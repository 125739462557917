
<template>
  <div
    :style="cssProps"
    class="container"
  >
    <h3>
      {{ $t('file-upload.title') }}
    </h3>
    <p class="mb-2">
      {{ $t('file-upload.subtitle') }} <br> <a
        @click="moreInfoDrawer=true"
      >
        {{ $t('file-upload.file-link') }}
      </a>
    </p>
    <div
      class="mt-4 drag-drop-zone flex-grow-1"
      :class="{'drag-drop-zone-hover':isDraging, 'drag-drop-zone-error red':uploadError.length}"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent
      @dragleave.prevent="onDragLeave"
      @drop.prevent="addFile"
    >
      <template
        v-if="isUploadingFile"
      >
        <div v-if="intervalValue < 100">
          <h4 class="mb-4">
            {{ $t('file-upload.upload-check') }}
          </h4>
          <v-progress-circular
            :size="100"
            :width="7"
            indeterminate
            color="white"
          >
            <h4>{{ intervalValue }} % </h4>
          </v-progress-circular>
        </div>
        <div v-else>
          <h4 class="mb-4">
            {{ $t('file-upload.upload-success') }}
          </h4>
          <v-icon
            color="white"
            class="mr-2"
            size="100"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </template>

      <template v-else>
        <div v-if="uploadError.length">
          <v-icon
            color="white"
            class="mr-2"
            size="100"
          >
            mdi-alert-circle
          </v-icon>
          {{ uploadError }}
        </div>

        <div v-if="isDraging && !uploadError.length">
          <v-icon
            color="white"
            class="mr-2"
            size="100"
          >
            mdi-check-circle
          </v-icon>
        </div>
        <div v-if="!isDraging && !uploadError.length">
          <div>
            {{ $t('file-upload.upload-hints.0') }}
          </div>
          <div class="mt-4">
            {{ $t('file-upload.upload-hints.1') }}
          </div>
          <div class="mt-4">
            <v-btn
              color="primary"
              @click="clickFileInput"
            >
              {{ $t('buttons.file-upload.cta') }}
            </v-btn>
            <input
              ref="fileInput"
              type="file"
              class="hidden-input"
              :accept="acceptedFileTypes.join(',')"
              @change="addFile"
            >
          </div>
        </div>
      </template>
    </div>

    <div
      v-if="uploadError.length"
      class="justify-end mt-4 d-flex align-end"
    >
      <v-btn
        text
        color="primary"
        type="button"
        :disabled="!uploadError.length"
        @click="resetImportData"
      >
        {{ $t('buttons.file-upload.start-new') }}
      </v-btn>
    </div>

    <v-navigation-drawer
      v-model="moreInfoDrawer"
      absolute
      bottom
      temporary
      width="60%"
    >
      <div class="pl-8">
        <div class="d-flex">
          <h4 class="mt-4">
            {{ $t('file-upload.file-link') }}
          </h4>
          <v-spacer />
          <v-btn
            icon
            @click="moreInfoDrawer=false"
          >
            <v-icon large>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <p>
          {{ $t('file-upload.info-drawer.body-texts.0') }}<br>
          <br>
          Folgende weitere Daten können angegeben werden:
          <ul class="pt-2">
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Telefonnummer</li>
          </ul><br>

          {{ $t('file-upload.info-drawer.body-texts.2') }}
        </p>
        <v-img
          src="img/preview-table.png"
          alt="Beispiel Datei"
          width="90%"
        />
        <div class="mt-8">
          <span>
            {{ $t('file-upload.info-drawer.body-texts.3') }} <br>
            {{ $t('file-upload.info-drawer.body-texts.4') }}
          </span>

          <div class="mt-2 d-flex align-center">
            <span>{{ $t('file-upload.info-drawer.body-texts.5') }}</span>
            <v-select
              v-model="selectedImportFileType"
              class="ml-4 mr-4 example-file-select"
              :items="importFileTypes"
              outlined
              hide-details
              dense
            />
            <v-btn
              :disabled="!selectedImportFileType"
              color="primary"
              :href="url"
              target="_blank"
              rel="noopener noreferrer"
              small
            >
              {{ $t('buttons.file-upload.download') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ImportFileTypes from '../enums/ImportFileTypes'
import brandingMixin from '@/mixins/branding'

const referenceMapper = {
  CSV: 'bottimmo-referenzfile-csv-utf8-import/bottimmo-referenzfile-csv-utf8-import.csv',
  XLS: 'bottimmo-referenzfile-xls-import/bottimmo-referenzfile-xls-import.xls',
  XLSX: 'bottimmo-referenzfile-xlsx-import/bottimmo-referenzfile-xlsx-import.xlsx'
}

export default {
  mixins: [brandingMixin],
  props: {
    reset: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploadError: '',
      interval: {},
      intervalValue: 0,
      isDraging: false,
      moreInfoDrawer: false,
      isUploadingFile: false,
      selectedImportFileType: '',
      dropCounter: 0,
      importFileTypes: Object.keys(ImportFileTypes)
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    },
    url () {
      return `https://${this.platformAssetUrl}/assets/import/${referenceMapper[this.selectedImportFileType]}`
    },
    acceptedFileTypes () {
      return ['text/csv', 'application/vnd.ms-excel', 'application/msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    }
  },
  watch: {
    reset () {
      this.resetImportData()
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    onDragEnter () {
      this.dropCounter++
      this.updateDropZone(true)
    },
    onDragLeave () {
      this.dropCounter--

      if (this.dropCounter === 0) {
        this.updateDropZone(false)
      }
    },
    updateDropZone (isDraging) {
      this.isDraging = isDraging
    },
    clickFileInput () {
      this.$refs.fileInput.click()
    },
    resetImportData () {
      this.uploadError = ''
      this.updateDropZone(false)
      this.intervalValue = 0
      this.isUploadingFile = false
      this.$emit('reset-finished')
    },
    async uploadFile (files) {
      this.uploadError = ''

      if (!files) {
        return this.handleError(this.$t('alerts.file-upload.no-files'))
      }

      if (!files || files.length < 1) {
        return this.handleError(this.$t('alerts.file-upload.min-one-file'))
      }

      if (files.length !== 1) {
        return this.handleError(this.$t('alerts.file-upload.only-one-file'))
      }

      const file = files[0]

      if (!this.acceptedFileTypes.includes(file.type)) {
        return this.handleError(this.$t('alerts.file-upload.file-invalid'))
      }

      try {
        const parsedData = await this.parseFile(file)
        const columnNames = parsedData[0]
        const data = parsedData.slice(1).map(dataset => {
          const obj = {}
          dataset.forEach((value, index) => {
            obj[columnNames[index]] = value
          })
          return obj
        })
        if (!data.length) {
          return this.handleError(this.$t('alerts.file-upload.no-files'))
        }
        const exampleDataset = data[0]
        this.$emit('upload', { fileName: file.name, columnNames, exampleDataset, data })
      } catch (error) { }
      this.resetImportData()
    },

    handleError (message) {
      this.uploadError = message
      this.isUploadingFile = false
      this.$emit('upload-error')
    },

    addFile (e) {
      e.preventDefault()
      const files = e.dataTransfer?.files || e.target?.files

      this.updateDropZone(true)
      this.isUploadingFile = true

      // Add fake progress bar
      this.interval = setInterval(() => {
        if (this.intervalValue >= 120) {
          clearInterval(this.interval)
          this.uploadFile(files)
        }
        this.intervalValue += 10
      }, 250)
    },

    async parseFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = async (e) => {
          const XLSX = await import(/* webpackChunkName: "xlsx" */'xlsx')
          const jschardet = await import(/* webpackChunkName: "jschardet" */'jschardet')

          const binaryString = e.target.result
          const fileEncoding = jschardet.detect(binaryString).encoding

          /* Parse data */
          const workbook = XLSX.read(binaryString, { raw: true, type: 'binary', cellText: false, cellDates: true })

          /* Get first worksheet */
          const wsname = workbook.SheetNames[0]
          const ws = workbook.Sheets[wsname]

          /* Decoding for UTF-8 CSV */
          if (file.type === 'text/csv' && fileEncoding === 'UTF-8') {
            Object.keys(ws).forEach(key => {
              const value = ws[key].v
              if (typeof value === 'string') {
                try {
                  ws[key].v = decodeURIComponent(escape(value))
                } catch (err) {
                  ws[key].v = value
                }
              }
            })
          }

          /* Convert array of arrays */
          resolve(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'dd"."mm"."yyyy' }))
        }

        reader.onerror = reject

        reader.readAsBinaryString(file)
      })
    }
  }

}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drag-drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  min-height:100px;
}

.drag-drop-zone-hover{
  background-color: var(--primary-color);
  color:white
}

.drag-drop-zone-error{
  color:white
}

.hidden-input {
  position: absolute;
  left: -2000px;
}

.example-file-select{
  max-width: 100px;
}
</style>
